import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import ContactForm from "../components/contactForm/form";
import Faq from "../components/faq/faq";
import Features from "../components/features/features";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ImageText from "../components/imageText/imageText";
import Integrations from "../components/integrations/integrations";
import News from "../components/news/news";
import Seo from "../components/seo";
import SingleDevice from "../components/singleDevice/singleDevice";
import Testimonials from "../components/testimonials/testimonials";
import Top from "../components/top/top";
import iconAdv1 from "../images/Franchise/icon-adv-1.png";
import iconAdv2 from "../images/Franchise/icon-adv-2.png";
import iconAdv3 from "../images/Franchise/icon-adv-3.png";

import testimonialsState from "./../utils/testimonials";

//  markup
const FranchisePage = ({ location, data }) => {
  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const topProps = {
    layout: 1,
    scrollToForm: scrollToForm,
    title: (
      <>
        Maximize your franchise
        <br className="d-inline-block d-xl-none" /> profit with our cutting
        <br className="d-inline-block d-xl-none" /> edge
        <br className="d-none d-xl-inline-block" /> solution
      </>
    ),
    subtitle: `Online ordering consolidation with Orders.co means having a one-stop shop for orders,
            menu management, and reporting. Improve fulfillment efficiency by removing tablets and
            generate sales reports in one click`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="franchise"
        src="../images/Franchise/franchise-orders.co.png"
        className="mw-100"
      />
    ),
  };

  const features = [
    {
      bg: "#DEFFEE",
      icon: iconAdv1,
      title: "Location Access",
      description:
        "Individual Store Operators maintain management control over their specific location.",
    },
    {
      bg: "#F1F7FF",
      icon: iconAdv2,
      title: "Corporate Management",
      description:
        "Admin user accounts have full access to all connected stores & brands.",
    },
    {
      bg: "#FFF7E3",
      icon: iconAdv3,
      title: "Chain Reporting",
      description:
        "Consolidate online sales into one platform and review analytics across all connected stores.",
    },
  ];

  const singleDeviceProps = {
    title: `Easily manage your restaurant's online orders with a single device`,
    image: (
      <>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="Restaurant Menu Management"
          src="../images/menu-management_tablet.png"
          className="mw-100"
        />
      </>
    ),
    scrollToForm: scrollToForm,
  };

  const testimonialsProps = {
    layout: 1,
    items: [
      // testimonialsState.silver_chicken_and_seafood,
      testimonialsState.slash_pizza,
      testimonialsState.c_viche,
      testimonialsState.jonahs_kabob,
    ],
    title: "Hear From Our Customers",
    tag: "Testimonials",
  };

  const imageTextProps1 = {
    layout: "equal-big",
    title: "Stress-Free Order Management",
    titleStyle: "smaller",
    imagePosition: "right",
    description: `Free yourself from the stress of managing incoming orders across multiple tablets with
            Orders.co’s integration system. Consolidate all of your online orders into one easy dashboard.
            Freeing up your staff and counter space.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="order management"
        src="../images/Franchise/Stress-Free Order Management.png"
      />
    ),
  };
  const imageTextProps2 = {
    layout: "equal-big",
    title: "A Team Behind You",
    titleStyle: "smaller",
    imagePosition: "left",
    description: `Access our 24/7 expert support team for all your technical, menu, and optimization needs.
            Our experienced staff can help make your day-to-day easier, while increasing your revenue.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="restaurant"
        src="../images/Franchise/restaurant.png"
      />
    ),
  };
  const imageTextProps3 = {
    layout: "equal-big",
    title: "15 Minute Onboarding",
    titleStyle: "smaller",
    imagePosition: "right",
    description: `Schedule a quick 15 minute phone call to give our integration team the necessary
            information, then sit back and let them take over. We provide regular updates on the status
            of your onboarding and will do all the heavy lifting for you.`,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="3rd party integration data"
        src="../images/Franchise/integration.png"
      />
    ),
  };

  const questions = [
    {
      question: `How long does it take to set up Orders.co?`,
      answer: `Orders.co’s integration and setup takes around 1-2 weeks, depending on the current workload.
                For a more detailed estimate, contact a sales representative.`,
    },
    {
      question: `How much does Orders.co setup cost?`,
      answer: `Orders.co setup fee is $100.`,
    },
    {
      question: `How does Orders.co process website transactions?`,
      answer: `Orders.co has partnered with Stripe for all online credit card processing.
                All restaurant partners are required to create and pair․`,
    },
    {
      question: `Does Orders.co offer a delivery service for web orders?`,
      answer: `Yes, Orders.co has partnered with UberEats to offer delivery for restaurants that do not
                have delivery staff. The delivery fee will be charged directly to the customer at checkout.
                There is also an option for In-House delivery for restaurants with existing staff.`,
    },
    {
      question: `How does Master Menu Management work with 100+ locations?`,
      answer: `Orders.co creates an Admin account with access to all connected locations.
                Menu updates can be made to all stores from one account. Individual locations will
                also have access to their own menus for changes.`,
    },
    {
      question: `Can we connect our existing ticket printers to Orders.co?`,
      answer: `You can connect your existing ticket printer to the Orders.co device in the settings panel.
                The printer must be internet compatible and be connected to the same network as the Orders.co
                device. For a full list of compatible printers, contact our support team.`,
    },
  ];

  return (
    <>
      <Seo
        title={"Franchise"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Top {...topProps} />
        <div
          className="bg-mobile mt-xl-0 pt-xl-0"
          style={{
            backgroundColor: "#F7F7F7",
            marginTop: "-80px",
            paddingTop: "40px",
          }}
        >
          <Features title="Top Benefits" items={features} />
        </div>
        <SingleDevice {...singleDeviceProps} />
        <Testimonials {...testimonialsProps} />
        <ImageText {...imageTextProps1} />
        <ImageText {...imageTextProps2} />
        <ImageText {...imageTextProps3} />
        <Integrations scrollToForm={scrollToForm}></Integrations>
        <News items={data.allWpPost} />
        <Faq items={questions} />
        <div ref={formRef}>
          <ContactForm
            title={
              <>
                Maximize Your
                <br /> Profit With
                <br /> Orders.co
              </>
            }
          ></ContactForm>
        </div>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "franchise" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
    allWpPost(
      sort: {fields: [date], order: DESC}
      limit: 3
      skip: 0
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "franchise"}}}}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            gatsbyImage(width: 600,quality: 80)
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, placeholder: TRACED_SVG, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;

export default FranchisePage;
